<template>
  <div>
    <header class="bg-subpage text-white mb-4" :style="{ backgroundImage: 'url(' + this.headerImage + ')' }">
      <div class="subpage-head">
        <Navbar :configData="this.configData" :navData="this.navData" />
      </div>
      <!--carousel-->
      <div class="container">
        <h1 class="col-12 text-center my-5 d-inline-block">{{ this.name }}</h1>
      </div>
    </header>

    <div class="container mt-5 mb-5 text-center">
      <h1>404</h1>
      <h5>A keresett oldal nem található!</h5>
    </div>
  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";
import Navbar from "@/components/nav/Navbar";

export default {
  name: "NotFound404",
  components: {
    Navbar
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Array]},
  },
  data() {
    return {
      name: "",
      description: "",
      products: [],
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
      headerImage: "",
    }
  },
  metaInfo() {
    return {
      title: "404 | "+SITE_TITLE,
      meta: [
        { name: "robots", content: "noindex" },
      ],
    }
  }
}
</script>

<style scoped>

</style>
