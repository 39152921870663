<template>
  <div>
    <preloader />
    <header
      class="bg-subpage text-white mb-4"
      :style="{ backgroundImage: 'url(' + this.headerImage + ')' }"
    >
      <div class="subpage-head">
        <Navbar :configData="this.configData" :navData="this.navData" />
      </div>
      <!--carousel-->
      <div class="container">
        <h1 class="col-12 text-center my-5 d-inline-block">
          {{ this.data.name }}
        </h1>
      </div>
    </header>

    <div class="container mb-3 py-3">
      <div v-if="this.data.other" v-html="this.data.other" class="my-4" />
      <div class="bg-white">
        <div
          data-bs-toggle="collapse"
          @click="toggle"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          class="bg-blue px-3 fomenu"
        >
          <div class="clearfix">
            <div class="col float-start py-2">
              <div class="row product-list-title">
                <div class="col-md-auto fw-bold">
                  <span class="text-white fas-icon">
                    <font-awesome-icon
                      v-if="isVisible"
                      :icon="['fas', 'angle-up']"
                    />
                    <font-awesome-icon v-else :icon="['fas', 'angle-down']" />
                    &#x7C; {{ this.data.name }}
                  </span>
                </div>
                <div class="col-md-auto menuel p-0">–</div>
                <div class="col-md-auto">
                  <span class="text-yellow fw-Light">{{
                    this.data.category_name
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col float-end py-1">
              <div class="icon-c icon-container">
                <span
                  class="discount circle fw-bold"
                  v-if="this.data.discount > 0"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="'Akció'"
                  :title="'Akció'"
                  >{{ this.data.discount }}%</span
                >
                <span v-for="(item, index) in this.data.badges" :key="index">
                  <img
                    class="icon mx-1"
                    :src="require(`@/assets/images/${item}`)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$setTooltipTitle(item)"
                    :data-bs-original-title="$setTooltipTitle(item)"
                /></span>
              </div>
            </div>
          </div>
        </div>
        <div class="collapse show" v-show="isVisible">
          <div class="card card-body">
            <div class="row">
              <div class="col-lg-4 py-3 product-details">
                <div class="text-blue fw-bold text-center">
                  Üzleti részletek
                </div>
                <hr />
                <!--almenu1-->
                <Html :data="this.data.details" />
              </div>
              <div class="col-lg-4 py-3 product-details">
                <div class="text-blue fw-bold text-center">
                  Technikai részletek
                </div>
                <hr />
                <div v-html="this.data.description" style="line-height: 3em" />
              </div>
              <div class="col-lg-4 py-3 text-center">
                <img
                  :src="this.data.image"
                  class="img-fluid"
                  :alt="this.data.name"
                  loading="lazy"
                />
              </div>
              <hr />
              <div class="mb-3 py-3">
                <Html :data="this.data.more_details" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container bg-white mb-3 py-3">
        <div class="clearfix">
          <div
            class="col float-start text-blue fw-bold"
            v-if="this.data.discount > 0"
          >
            EREDETI ÁR:
            {{ Number(this.data.original_price).toLocaleString() }} Ft + ÁFA
            <span v-if="this.data.unit">/ {{ this.data.unit }}</span>
          </div>
          <div class="col float-end text-blue fw-bold">
            <span v-if="this.data.price > 0"
              ><span v-if="this.data.discount > 0">PREMIUM</span> ÁR:
              {{ Number(this.data.price).toLocaleString() }} Ft + ÁFA
              <span v-if="this.data.unit">/ {{ this.data.unit }}</span>
            </span>
            <a :href="this.data.link" type="button" class="btn bg-yellow mx-3"
              >Konfigurálás</a
            >
          </div>
        </div>
      </div>

      <div v-if="this.related.length > 0" class="container">
        <h2 class="col-12 text-center my-5 text-uppercase">
          Kapcsolódó termékek
        </h2>
        <div v-for="(item, index) in this.related" :key="index">
          <ProductList :data="item" />
        </div>
      </div>

      <div v-for="item in this.modules" :key="item.module">
        <ModuleLoader
          :data="item.data"
          :type="item.module"
          :prefix="item.prefix"
          :configData="configData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModuleLoader from "@/components/ModuleLoader";
import { SITE_TITLE } from "@/config";
import ProductsApiService from "@/services/ProductsApiService";
import ProductList from "@/components/partials/ProductList";
import Navbar from "@/components/nav/Navbar";
import Html from "@/components/partials/Html";
import Helpers from "@/utils/Helpers";
import Preloader from "@/components/Preloader";

export default {
  name: "ProductDetail",
  components: {
    ModuleLoader,
    ProductList,
    Navbar,
    Html,
    Preloader,
  },
  props: {
    configData: { data: [Array] },
    navData: { data: [Object] },
  },
  data() {
    return {
      data: "",
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
      related: [],
      isVisible: true,
      headerImage: "",
    };
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.data.meta_description;
    const mKeywords = this.data.keywords;
    return {
      title: mTitle + " | " + SITE_TITLE,
      meta: [
        { name: "description", content: mDescription },
        { name: "keywords", content: mKeywords },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  updated() {
    Helpers.createTooltip();
  },
  beforeCreate() {
    document.body.className = "bg-white";
  },
  methods: {
    async getData() {
      this.domain = window.location.href;
      await ProductsApiService.getCategoryProducts(
        "product/" + this.$route.params.slug
      ).then((response) => {
        if (response) {
          this.data = response.data.product;
          this.metaTitle = response.data.product.name;
          this.modules = response.data.modules;
          this.related = response.data.related;
          this.headerImage = this.$setHeaderImage(null);
        }
      });
    },
    toggle() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style scoped></style>
