import axios from "axios";
//import router from "@/router";
import {API_BASE_URL} from "@/config";

class BaseApiService {

    constructor() {
        axios.defaults.withCredentials = true;
    }

    setHeader(key, value) {
        axios.defaults.headers.common[key] = value;
    }

    get(url = "") {
        return axios.get(API_BASE_URL + 'api/' + url);
    }

    post(url, params, options = {}) {
        return axios.post(API_BASE_URL + 'api/' + url, params, options);
    }

    put(url, params) {
        return axios.put(API_BASE_URL + 'api/' + url, params);
    }

    patch(url, params) {
        return axios.patch(API_BASE_URL + 'api/' + url, params);
    }

    delete(url) {
        return axios.delete(API_BASE_URL + 'api/' + url);
    }

    handleErrors(e) {
        if (e.response) {
            const status = e.response.status;
            if (status === 404) {
                window.location.replace('/404');
            } else if (status === 422) {
                return e.response;
            } else {
                return 'Authentication failed!';
            }
        }

    }

    csrf() {
        return axios.get(API_BASE_URL + 'sanctum/csrf-cookie');
    }
}

export default BaseApiService;
