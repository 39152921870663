
export default {
    install(Vue) {
        Vue.prototype.$setHeaderImage = function (image) {
            if (image) {
                return image;
            }
            return require('@/assets/images/head2.jpg');
        }

        Vue.prototype.$setTooltipTitle = function (image){
            let data={
                "sg_icon_korlatozott_ideig.png": "Korlátozott ideig",
                "sg_icon_quality.png": "High quality",
                "sg_icon_csak_nalunk.png": "Csak nálunk",
                "sg_icon_donteshozok.png": "Döntéshozók figyelmébe",
                "sg_icon_legjobb_ar.png": "Legjobb ár/érték arány",
                "sg_icon_uj.png": "Újdonság",
                "sg_icon_biztonsag.png": "Biztonság",
                "sg_icon_kedvelt.png": "Kedvelt szolgáltatás",
                "sg_icon_ajanljuk.png": "Ezt ajánljuk",
            };

            return data[image];
        }
    }
};