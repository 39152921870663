<template>
  <div>
    <div
      v-if="showOther && this.data.other"
      v-html="this.data.other"
      class="my-4"
    />
    <div
      data-bs-toggle="collapse"
      @click="toggle"
      role="button"
      aria-expanded="false"
      aria-controls="collapseExample"
      class="bg-blue px-3 fomenu"
    >
      <div class="clearfix">
        <div class="col float-start py-2">
          <div class="row product-list-title">
            <div class="col-md-auto fw-bold">
              <span class="text-white fas-icon">
                <font-awesome-icon
                  v-if="isVisible"
                  :icon="['fas', 'angle-up']"
                />
                <font-awesome-icon v-else :icon="['fas', 'angle-down']" />
              </span>
              &#x7C; {{ this.data.name }}
            </div>
            <div class="col-md-auto menuel p-0">–</div>
            <div class="col-md-auto">
              <span class="text-yellow fw-Light">{{
                this.data.category_name
              }}</span>
            </div>
          </div>
        </div>
        <div class="col float-end py-1">
          <div
            class="icon-c float-end"
            :class="{ 'icon-container': isVisible }"
          >
            <span
              class="discount circle fw-bold mx-1"
              v-if="this.data.discount > 0"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="'Akció'"
              :title="'Akció'"
              >{{ this.data.discount }}%</span
            >
            <span
              v-for="(item, index) in this.data.badges"
              :key="index"
              class="fw-bold"
            >
              <img
                class="icon mx-1"
                :src="require(`@/assets/images/${item}`)"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="$setTooltipTitle(item)"
                :data-bs-original-title="$setTooltipTitle(item)"
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse show" v-show="isVisible">
      <div class="card card-body">
        <div class="row">
          <div class="col-lg-4 py-3 product-details">
            <div class="fw-bold text-center">Üzleti részletek</div>
            <hr />
            <!--almenu1-->

            <Html
              class="text-blue"
              :data="this.data.details"
              :containerId="'p1-' + this.id"
            />
          </div>
          <div class="col-lg-4 py-3 product-details">
            <div class="text-blue fw-bold text-center">Technikai részletek</div>
            <hr />
            <div
              class="text-blue"
              v-html="this.data.description"
              style="line-height: 3em"
            />
          </div>
          <div class="col-lg-4 py-3 text-center">
            <img
              :src="this.data.image"
              class="img-fluid"
              :alt="this.data.name"
              loading="lazy"
            />
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div class="container bg-white mb-3 py-3">
      <div class="row clearfix">
        <div class="col-md-5 text-blue fw-bold">
          <div v-if="this.data.discount > 0">
            EREDETI ÁR:
            {{ Number(this.data.original_price).toLocaleString() }} Ft + ÁFA
            <span v-if="this.data.unit">/ {{ this.data.unit }}</span>
          </div>
        </div>
        <div class="col-md-5 text-blue fw-bold text-md-end mb-3 mb-md-0">
          <span v-if="this.data.price > 0"
            ><span v-if="this.data.discount > 0">PREMIUM</span> ÁR:
            {{ Number(this.data.price).toLocaleString() }} Ft + ÁFA
            <span v-if="this.data.unit">/ {{ this.data.unit }}</span></span
          >
        </div>
        <div class="col-md-2">
          <router-link
            :to="this.data.link"
            type="button"
            class="btn bg-yellow fw-bold"
            style="margin-top: -7px"
            >Részletek
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Html from "@/components/partials/Html";
import Helpers from "@/utils/Helpers";

export default {
  name: "ProductList",
  components: { Html },
  props: {
    data: { data: [Array] },
    index: { data: [Number] },
    open: { data: [Boolean] },
  },
  data() {
    return {
      isVisible: false,
      id: this._uid,
      showOther: false,
    };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
  },
  mounted() {
    if (this.index === 0 || this.open) {
      this.isVisible = true;
      this.showOther = true;
    }
    Helpers.createTooltip();
  },
};
</script>

<style scoped></style>
