import Vue from "vue";
import VueRouter from "vue-router";
import Template from "@/views/Template";
import Home from "@/components/contents/Home";
import Content from "@/components/contents/Content";
import NotFound404 from "@/components/NotFound404";
import Search from "@/components/contents/Search";
import ArticleDetail from "@/components/contents/ArticleDetail";
import CategoryProducts from "@/components/contents/CategoryProducts";
import ProductDetail from "@/components/contents/ProductDetail";
import Unsubscribe from "@/components/Unsubscribe";

Vue.use(VueRouter);

const Router = new VueRouter({
    mode: "history",
    routes: [{
        path: '/',
        component: Template,
        children: [
            {
                path: '/leiratkozas',
                name: 'nav.unsubscribe',
                component: Unsubscribe
            },
            {
                path: '/error/404',
                name: 'nav.notfound404',
                component: NotFound404
            },
            {
                path: '/kereses',
                name: 'nav.search',
                component: Search
            },
            {
                path: '',
                name: 'nav.index',
                component: Home,
                props: true
            },
            {
                path: '/termekeink/:slug',
                name: 'nav.category',
                component: CategoryProducts,
                props: true,
            },
            {
                path: '/termekeink/:category/:slug',
                name: 'nav.product',
                component: ProductDetail,
                props: true,
            },
            {
                path: '/:module/:year(\\d+)/:month(\\d+)/:day(\\d+)/:url',
                name: 'nav.article',
                component: ArticleDetail,
                props: true,
            },
            {
                path: '/:slug(.*)',
                name: 'nav.content',
                component: Content,
                props: true,
            },
        ]
    }
    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    }

});


export default Router
