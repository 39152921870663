<template>
<div>
  <preloader/>
  <header class="bg-subpage text-white mb-4" :style="{ backgroundImage: 'url(' + this.headerImage + ')' }">
    <div class="subpage-head">
      <Navbar :configData="this.configData" :navData="this.navData" />
    </div>
    <!--carousel-->
    <div class="container">
      <h1 class="col-12 text-center my-5 d-inline-block">{{ this.name }}</h1>
    </div>
  </header>

  <div v-if="this.products.length > 0" class="container">
      <div v-for="(item, index) in (this.products)" :key="index">
        <ProductList :data="item" :open="true" :index="index"/>
      </div>
  </div>

  <div v-for="item in (this.modules)" :key="item.module">
    <ModuleLoader :data="item.data" :type="item.module" :prefix="item.prefix" :configData="configData"/>
  </div>
</div>
</template>

<script>
import ModuleLoader from "@/components/ModuleLoader";
import {SITE_TITLE} from "@/config";
import ProductsApiService from "@/services/ProductsApiService";
import ProductList from "@/components/partials/ProductList";
import Navbar from "@/components/nav/Navbar";
import Preloader from "@/components/Preloader.vue";
export default {
  name: "CategoryProducts",
  components: {
    ModuleLoader,
    ProductList,
    Navbar,
    Preloader
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Array]},
  },
  data() {
    return {
      name: "",
      description: "",
      products: [],
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
      headerImage: "",
    }
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.description;
    return {
      title: mTitle + " | " + SITE_TITLE,
      meta: [
        {name: 'description', content: mDescription},
      ]
    }
  },
  mounted() {
    this.getData();
  },
  "watch": {
    $route() {
      this.getData();
    }
  },
  beforeCreate() {
    document.body.className = 'bg-white';
  },
  methods: {
    async getData() {
      this.domain = window.location.href;
      await ProductsApiService.getCategoryProducts('products/' + this.$route.params.slug)
          .then(response => {
            if (response) {
              this.name = response.data.name;
              this.metaTitle = response.data.name;
              this.modules = response.data.modules;
              this.products = response.data.products;
              this.headerImage=this.$setHeaderImage(null);
            }
          });
    }
  }
}
</script>

<style scoped>

</style>