<template>
  <div class="unsubscribeContainer">
    <header class="bg-subpage text-white mb-4">
      <div class="subpage-head">
        <Navbar :configData="this.configData" :navData="this.navData" />
      </div>
    </header>

    <div class="unsubscribe-form-wrapper">
      <h1>Hírlevél Leiratkozás</h1>
      <div class="col-lg-12">
        <form
          class="unsubscribe-form"
          id="unsubscribe-form"
          ref="unsubscribe-form"
          action=""
          @submit.prevent="postForm"
        >
          <div class="col-lg-12">
            <input
              v-model="$v.contact.email.$model"
              type="text"
              name="email"
              maxlength="100"
              class="bb-bottom"
              :class="{ 'is-invalid': $v.contact.email.$error }"
              placeholder="E-mail cím*"
            />
          </div>
          <div class="unsubscribeVerification">
            <div class="col float-start py-1">
              <div class="form-check col-lg-12">
                <input
                  type="checkbox"
                  name="privacy"
                  id="unsubscribeCheckbox"
                  v-model="checked"
                  class="form-check-input"
                />
                <label
                  class="form-check-label text-blue fs-6"
                  for="unsubscribeCheckbox"
                >
                  Biztosan le akarok iratkozni *
                </label>
              </div>
            </div>
            <div>
              <input :value="this.contact.source" type="hidden" name="source" />
              <button
                type="button "
                class="btn bg-yellow unsubscribeBtn"
                :disabled="!this.checked"
              >
                Leiratkozás
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      ref="success-modal"
      class="modal"
      tabindex="-1"
      v-show="this.visible.successModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Sikeres leiratkozás!</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="this.closeModal"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="error-modal"
      class="modal"
      tabindex="-1"
      v-show="this.visible.errorModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Hiba történt!</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="this.closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <validation-error
              :errors="validationErrors"
              v-if="validationErrors"
            ></validation-error>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/nav/Navbar";
import CrmApiService from "@/services/CrmApiService";
import ValidationError from "@/components/partials/ValidationError";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "Unsubscribe",
  components: {
    ValidationError,
    Navbar,
  },
  props: {
    configData: { data: [Array] },
    navData: { data: [Array] },
  },
  data() {
    return {
      visible: {
        successModal: false,
        errorModal: false,
      },
      checked: false,
      contact: {
        email: "",
      },
      validationErrors: "",
    };
  },
  validations: {
    contact: {
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(100),
      },
    },
  },
  methods: {
    postForm: function () {
      this.$v.contact.$touch();
      if (this.$v.contact.$error) {
        return;
      }
      this.checked = true;
      CrmApiService.postUnsubscribeForm(this.contact).then((response) => {
        if (response.data.success) {
          if (response.data.success === 1) {
            this.visible.successModal = true;
            this.$refs.contactForm.reset();
          }
        } else {
          if (response.data.errors) {
            this.validationErrors = response.data.errors;
          }
          this.visible.errorModal = true;
          this.checked = false;
        }
      });
    },
    closeModal() {
      this.visible.successModal = false;
      this.visible.errorModal = false;
    },
  },
  metaInfo() {
    return {
      title: "Leíratkozás",
    };
  },
};
</script>

<style scoped></style>
