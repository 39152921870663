var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('preloader'),_c('header',{staticClass:"bg-subpage text-white mb-4",style:({ backgroundImage: 'url(' + this.headerImage + ')' })},[_c('div',{staticClass:"subpage-head"},[_c('Navbar',{attrs:{"configData":this.configData,"navData":this.navData}})],1),_vm._m(0)]),_c('div',{staticClass:"container px-0 mt-5 mb-5"},[(this.content.length > 0)?_c('div',_vm._l((this.content),function(item,index){return _c('div',{key:index,staticClass:"result-wrapper mb-3 pb-3"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}}),(item.prefix)?_c('div',[_c('router-link',{staticClass:"nav-link px-0 text-blue",attrs:{"to":item.prefix +
              '/' +
              _vm.createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link}},[_vm._v("Tovább "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1)],1):_c('div',[_c('router-link',{staticClass:"nav-link px-0 text-blue tovabbBtn",attrs:{"to":'/' + item.link}},[_vm._v("Tovább "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1)],1)])}),0):_vm._e(),(this.products.length > 0)?_c('div',_vm._l((this.products),function(item,index){return _c('div',{key:index,staticClass:"result-wrapper mb-3 pb-3"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('div',[_c('router-link',{staticClass:"nav-link px-0 text-blue tovabbBtn",attrs:{"to":item.link}},[_vm._v("Tovább "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1)],1)])}),0):_vm._e(),(this.faq.length > 0)?_c('div',_vm._l((this.faq),function(item,index){return _c('div',{key:index,staticClass:"result-wrapper mb-3 pb-3"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('p',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('div',[_c('router-link',{staticClass:"nav-link px-0 text-blue tovabbBtn",attrs:{"to":item.link}},[_vm._v("Tovább "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1)],1)])}),0):_vm._e(),(
        this.content.length === 0 &&
        this.products.length === 0 &&
        this.faq.length === 0
      )?_c('div',[_c('h5',{staticClass:"text-center mt-5 mb-5"},[_vm._v("Nincs találat!")])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"col-12 text-center my-5 d-inline-block"},[_vm._v("Keresés")])])}]

export { render, staticRenderFns }